import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import "./admin.css";

export function AdminLogin() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button className="btn-login" onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  );
}

export default AdminLogin;
