import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import "./Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Index } from "../../../pages/Admin/AdminCategory";
import { useAuth0 } from "@auth0/auth0-react";

export const ModalDelete = ({ closeDeleteModal }) => {
  async function getCategories() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    await axios
      .post(process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`, product, {
        headers: {
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        const cats = res.data;
        setRows(cats);
      });
  }

  async function getProducts() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    try {
      await axios
        .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          const cats = res.data;
          setRowsP(cats);
        });
    } catch (err) {
      toast.error("An error has occured while getting the products.");
    }
  }

  function getCategoryImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/images/category`, {})

      .then((res) => {
        const cats = res.data;
        setImgs(cats);
      });
  }
  useEffect(() => {
    getProducts();
    getCategoryImages();
    getCategories();
  }, []);

  const [imgs, setImgs] = useState([]);
  const [rowsP, setRowsP] = useState([]);
  const [rows, setRows] = useState([]);
  const [deleteText, setDeleteText] = useState("");
  const [buttonText, setButtonText] = useState("Annuler");
  const [animation, setAnimation] = useState("");
  const [validated, setValidated] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  async function handleSubmit() {
    const toDelete = Index;

    const toDeleteChildren = rowsP.find(
      (p) => p.categoryId == toDelete.categoryId
    );
    //checking if there is any products in the category...
    if (toDeleteChildren == null) {
      try {
        setButtonText("Deleting...");
        setAnimation("border");

        const category = {
          target: toDelete.categoryId,
          UserId: user.user.Id,
        };

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });
        axios.post(process.env.REACT_APP_AUTH0_API + `api/admin`, category, {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      } catch (err) {
        toast.error("An error has occured while deleting product");
      }
      try {
        try {
          const fileExtension = imgs
            .find((i) => i.ObjectName.split(".")[0] == toDelete.categoryId)
            ?.ObjectName.split(".")[1];
          const response = await axios.delete(
            "https://ny.storage.bunnycdn.com/" +
              process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
              "categories/" +
              toDelete.categoryId +
              "." +
              fileExtension,
            {
              headers: {
                AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
                "content-type": "application/octet-stream",
              },
            }
          );

          toast.success("Image for " + toDelete.name + " was removed!");
        } catch (err) {
        } finally {
          setAnimation("");
          setButtonText("Delete image");
        }
        setAnimation("border");
      } catch (error) {
      } finally {
        toast.success("Categorie Supprimé: " + toDelete.name);
        setValidated("none");
        setAnimation("");
        setButtonText("Ok");
      }
    }
    //if there is products...
    else {
      setDeleteText(
        "Supprimer tous objets dans: " + toDelete.name + " avant de supprimer"
      );
      toast.error(toDelete.name + " contient des produits");
    }
  }

  return (
    <div className="modal-container">
      <div className="modal-delete">
        <p className="modal-delete-text">
          Êtes-vous certain de votre decision?
          <br />
          Vous allez supprimer: {Index.name}
        </p>
        <p className="modal-delete-text">{deleteText}</p>
        <button
          type="submit"
          className="btn-close-modal"
          onClick={() => handleSubmit()}
          style={{ display: validated }}
        >
          <Spinner animation={animation} role="status" size="sm" />
          {"  "}
          Supprimer
        </button>
        &emsp;
        <button className="btn-submit" onClick={() => closeDeleteModal()}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};
