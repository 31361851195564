import React from "react";
import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

function PersonAdd() {
  const [visible, setVisible] = useState(false);
  // const[Alert,setAlert] = useState(false);
  const [validated, setValidated] = useState(false);
  const [FirstName, setFirstname] = useState("");
  const [LastName, setLastname] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Id] = useState("");

  if (visible) {
    return (
      <div className="Alert">
        <Container className="p-4">
          <Alert
            variant="success"
            onClose={() => setVisible(false)}
            dismissible
            className="alertcontent"
          >
            <Alert.Heading>Ooops! an error occured!</Alert.Heading>
            <p>Update your form and run again</p>
          </Alert>
        </Container>
      </div>
    );
  }

  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleFirstNameInput = (e) => {
    setFirstname(e.target.value);
  };
  const handleLastNameInput = (e) => {
    setLastname(e.target.value);
  };
  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };
  const handleMessageInput = (e) => {
    setMessage(e.target.value);
  };
  //   const handleChange = (event) => {
  //     this.setState({ name: event.target.value });
  //   };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() == false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    const user = {
      FirstName: FirstName,
      LastName: LastName,
      Phone: Phone,
      Email: Email,
      Message: Message,
    };

    axios.post(`https://localhost:44350/api/forms`, user).then((res) => {
      setVisible(res.data);
    });

    // console.log(response)
  };

  return (
    //noValidate validated={validated}
    <div className="forms1">
      <Form
        className="formstrue"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Col className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              value={FirstName}
              name="firstname"
              noValidate
              onChange={handleFirstNameInput}
              isInvalid={FirstName == ""}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              value={LastName}
              name="lastname"
              onChange={handleLastNameInput}
              noValidate
              isInvalid={LastName == ""}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>Phone number</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">+1</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Phone number"
                aria-describedby="inputGroupPrepend"
                required
                value={Phone}
                name="phone"
                onChange={handlePhoneInput}
                noValidate
                isInvalid={Phone == ""}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a phone number.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom03">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              required
              name="email"
              value={Email}
              onChange={handleEmailInput}
              noValidate
              isInvalid={Email == ""}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom04">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Message"
              required
              name="message"
              value={Message}
              onChange={handleMessageInput}
              noValidate
              isInvalid={Message == ""}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a Message.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="submitbut">
            <div>
              <Button onClick={handleSubmit}>Submit form</Button>
            </div>
          </div>
        </Col>
      </Form>
    </div>
  );
}

export default PersonAdd;
