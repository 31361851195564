import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import "./ModalP.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Product } from "../../../pages/Admin/AdminProducts";
import Button from "react-bootstrap/esm/Button";
import Alert from "react-bootstrap/Alert";
import { useAuth0 } from "@auth0/auth0-react";
import Stack from "react-bootstrap/Stack";

export const ModalDeleteProduct = ({ closeDeleteProductModal }) => {
  const [imgs, setImgs] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsO, setRowsO] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();
  function getCatImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/images`)
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }
  async function getProducts() {
    const product = {
      UserId: user.user.Id,
    };
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    await axios
      .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
        headers: {
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const cats = res.data;
        setRows(cats);
      });
  }

  async function getProductOptions() {
    const product = {
      UserId: user.user.Id,
    };
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    await axios
      .post(process.env.REACT_APP_AUTH0_API + `api/productOption`, product, {
        headers: {
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        const cats = res.data;
        setRowsO(cats);
      });
  }
  useEffect(() => {
    getProductOptions();
    getProducts();
    getCatImages();
  }, []);
  const [buttonText, setButtonText] = useState("Annuler");
  const [animation, setAnimation] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState("");
  const [deleteCSS, setDeleteCSS] = useState("btn-submit");
  const [alertText, setAlertText] = useState(
    "Êtes-vous certain de votre decision? Vous allez supprimer: "
  );

  async function handleSubmit(e, productToDel) {
    productToDel = Product;
    const toDelete = rows.findIndex(
      (p) => p.productId === productToDel.productId
    );

    const toDeleteChildren = rowsO.find(
      (o) => o.product.productId === Product.productId
    );
    //checking if there is any products in the category...
    if (toDeleteChildren == null) {
      setRows(rows.filter((_, idx) => idx !== toDelete));

      try {
        setAlertText("Deleting... : ");
        setDeleteCSS("btn-close-modal");
        setValidated("none");
        setDisabled(true);
        setAnimation("border");
        setButtonText("Deleting...");
        const target = productToDel.productId;

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });

        const product = {
          target: productToDel.productId,
          UserId: user.user.Id,
        };
        await axios
          .post(
            process.env.REACT_APP_AUTH0_API + `api/admin/products`,
            product,
            {
              headers: {
                Authorization: accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            toast.success("Supprimé: " + productToDel.name);
          });
      } catch (err) {
        toast.error(err.response.data);
      }

      try {
        const fileExtension = imgs
          .find((i) => i.ObjectName.split(".")[0] == Product.productId)
          .ObjectName.split(".")[1];

        const response = await axios.delete(
          "https://ny.storage.bunnycdn.com/" +
            process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
            "products/" +
            Product.productId +
            "." +
            fileExtension,
          {
            headers: {
              AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
              "content-type": "application/octet-stream",
            },
          }
        );

        toast.success("Image for " + Product.name + " was removed!");
        setAlertText("Succesfully deleted: ");
      } catch (error) {
        console.info("No image was deleted");
      } finally {
        setDeleteCSS("btn-submit");
        setDisabled(false);
        setAnimation("");
        setButtonText("Ok");
      }
    } else {
      toast.error("Please delete all Product Options before deleting");
    }
  }

  return (
    <div className="modal-container">
      <div className="modal-deleteP">
        <Alert className="modal-delete-text">
          {alertText} {Product.name}
        </Alert>
        <Stack gap={4} className="col-md-5 mx-auto">
          <Button
            type="button"
            className="btn-close-modal"
            onClick={() => handleSubmit()}
            style={{ display: validated }}
          >
            Supprimer
          </Button>
          <Button
            className={deleteCSS}
            onClick={() => closeDeleteProductModal()}
            disabled={disabled}
          >
            <Spinner animation={animation} role="status" size="sm" />
            {"  "}
            {buttonText}
          </Button>
        </Stack>
      </div>
    </div>
  );
};
