import React from "react";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Right",
        alignItems: "Right",
        height: "100vh",
        width: "100vh",
      }}
    >
      <h1>Welcome to Admin Panel</h1>
    </div>
  );
};

export default Home;
