import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import "./admin.css";

export function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <Button
      className="btn-login"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log Out
    </Button>
  );
}

export default LogoutButton;
