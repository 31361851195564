import React from "react";
import "../../Products/Shop.css";
import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function CategoryDisplay() {
  const [filter, setFilter] = useState([]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [pdfs, setPDFs] = useState([]);
  const [rowsProdOpt, setRowsProdOpt] = useState([]);
  const [animation, setAnimation] = useState("border");

  function getImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + "api/images/category")
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
        console.log(err);
      });
  }

  function getCategories() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/category`, {})
      .then((res) => {
        const Prods = res.data;

        setRows2(Prods.filter((c) => c.status != false));
      });
  }

  useEffect(() => {
    getImages();
    getCategories();
  }, []);
  //

  return (
    /*Categories*/
    <Container fluid>
      <Row xs={2} md={4} className="g-4">
        {rows2.map((categories) => {
          const img = imgs.find(
            (i) => i.ObjectName.split(".")[0] == categories.categoryId
          );
          function loadingIconStyle() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "spinner-Style";
            } else {
              return "";
            }
          }

          function imageAvailable() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return;
            } else {
              return (
                process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName
              );
            }
          }

          function animation() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "border";
            } else {
              return "";
            }
          }
          const href = "/products#" + categories.name;
          return (
            <Col key={categories.categoryId}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                href={href}
              >
                <Card
                  style={{
                    border: "none",
                    maxWidth: "22rem",
                  }}
                >
                  <Card.Title
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {categories.name}
                  </Card.Title>
                  <Image
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      maxWidth: "14rem",
                    }}
                    id="imageLoad"
                    src={imageAvailable()}
                    rounded
                  />
                  <div className={loadingIconStyle()}>
                    <Spinner animation={animation()} role="status" size="sm" />
                  </div>{" "}
                </Card>
              </Button>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
export default CategoryDisplay;
