import Spinner from "react-bootstrap/Spinner";

function Loading() {
  return (
    <>
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    </>
  );
}

export default Loading;
