import React from "react";
import CategoryDisplayProducts from "../components/Admin/Category/CategoryDisplayProducts";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";

export const Products = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <CategoryDisplayProducts />
      </Row>
    </Container>
  );
};
