import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import "./ModalP.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultCategory } from "../../../pages/Admin/AdminProducts";
import ReactDOM from "react-dom";
import { useAuth0 } from "@auth0/auth0-react";
// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond";
import Fade from "react-bootstrap/Fade";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ModalProduct = ({ closeModal, onSubmit, defaultValue }) => {
  const [rowsProd, setRowsProd] = useState([]);
  const [rows, setRows] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  async function getProducts() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    try {
      await axios
        .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const Prods = res.data;

          setRowsProd(Prods);
        });
    } catch (err) {
      toast.error("A problem occured while getting the products.");
    }
  }
  async function getCategories() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    try {
      await axios
        .post(
          process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`,
          product,
          {
            headers: {
              Authorization: accessToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const cats = res.data;

          setRows(cats);
        });
    } catch (err) {
      toast.error("A problem occured when getting the categories.");
    }
  }
  const [formState, setFormState] = useState(
    defaultValue || {
      nom: "",
      status: "Actif",
      category: defaultCategory,
      description: "",
      file: "",
    }
  );

  const [files, setFiles] = useState([]);
  const [buttonText, setButtonText] = useState("Cancel");
  const [ButtonCss, setButtonCss] = useState("btn-close-modal");
  const [cancelButton, setCancelButton] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showErrDesc, setShowErrDesc] = useState(false);
  const [validated, setValidated] = useState("");
  const [animation, setAnimation] = useState("");

  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Actif");
  const [nom, setNom] = useState("");
  const [category, setCategory] = useState("");

  const validateForm = () => {
    const regex = /^[[A-Za-zÀ-ÖØ-öø-ÿ0-9-. ,!?:()%&*$#@~/]*$/;

    if (
      !formState.nom ||
      !regex.test(formState.nom) ||
      !formState.description ||
      !regex.test(formState.description)
    ) {
      if (!formState.nom || !regex.test(formState.nom)) {
        setShowErr(true);
      }
      if (!formState.description || !regex.test(formState.description)) {
        setShowErrDesc(true);
      }

      if (formState.description && regex.test(formState.description)) {
        setShowErrDesc(false);
      }
      if (formState.nom && regex.test(formState.nom)) {
        setShowErr(false);
      }
      toast.error("Erreur lors de l'ajout");
    } else {
      setShowErr(false);
      setShowErrDesc(false);
      setValidated("none");
      return true;
    }
  };

  async function handleSubmit(e) {
    let rowsprod;
    const imagefile = files[0]?.file;
    function verify() {
      if (imagefile != null) {
        const imageFileType = "." + imagefile.type.split("/")[1];
        if (
          imageFileType == ".jpg" ||
          imageFileType == ".png" ||
          imageFileType == ".jpeg" ||
          imageFileType == ".raw"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    if (verify()) {
      try {
        const product = {
          Nom: formState.nom,
          StatusY: status,
          CategoryName: formState.category,
          Description: formState.description,
          UserId: user.user.Id,
        };

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });

        setAnimation("border");

        if (!validateForm()) return;
        setButtonCss("btn-submit");
        setCancelButton(true);
        setButtonText("Adding product...");

        await axios
          .post(
            process.env.REACT_APP_AUTH0_API + `api/admin/products`,
            product,
            {
              headers: {
                Authorization: accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            toast.success("Added: " + formState.nom);
            return res.data.token;
          });

        e.preventDefault();

        await axios
          .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
            headers: {
              Authorization: accessToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const Prods = res.data;
            rowsprod = Prods;
          });

        if (files[0]?.file != null) {
          const Product = rowsprod[rowsprod.length - 1];
          const imagefile = files[0]?.file;
          const imageFileType = "." + imagefile.type.split("/")[1];
          console.debug(imageFileType);

          const response = await axios.put(
            "https://ny.storage.bunnycdn.com/" +
              process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
              "products/" +
              Product.productId +
              imageFileType,
            imagefile,
            {
              headers: {
                AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
                "Content-Type": "application/octet-stream",
              },
            }
          );

          toast.success("Added: " + formState.nom);
          toast.success(imagefile.name + " was Uploaded!");
        }
        setButtonCss("btn-submit");
        setButtonText("Ok");
        onSubmit(formState);
      } catch (err) {
        toast.error(err.response.data);
        setButtonText("Ok");
      } finally {
        setCancelButton(false);
        setAnimation("");
      }
    } else {
      toast.error("Inavlid image format");
    }
  }

  function handleNomChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setNom(e.target.value);
  }

  function handleDescriptionChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setDescription(e.target.value);
  }

  function handleStatusChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setStatus(e.target.value);
  }

  function handleCategoryChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setCategory(e.target.value);
  }

  return (
    <div className="modal-containerP">
      <div className="modalsP">
        <form>
          {/* Image */}
          <div className="form-group">
            <label htmlFor="file" className="image-Option">
              Image
            </label>

            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={false}
              maxFiles={1}
              name="files" /* sets the file input name, it's filepond by default */
              labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            />
            <p className="image-Information">
              Image will get automatically uploaded once product has been added
            </p>
          </div>
          {/* Nom */}
          <div className="form-group">
            <label htmlFor="description" className="image-Option">
              Name
            </label>
            <textarea
              name="nom"
              onChange={handleNomChange}
              value={formState.nom}
              id="description"
            />
          </div>
          <Alert show={showErr} className="errorAlert">
            Caractères invalides
            <br />
            ou "Nom" vide.
          </Alert>
          {/* status */}
          <div className="form-group">
            <label htmlFor="status" className="image-Option">
              Status
            </label>
            <select
              className="image-Option"
              name="status"
              onChange={handleStatusChange}
              value={formState.status}
              id="status"
            >
              <option className="image-Option" value="Actif">
                Actif
              </option>
              <option className="image-Option" value="Inactif">
                Inactif
              </option>
            </select>
          </div>
          {/* CategoryId */}
          <div className="form-group">
            <label htmlFor="category" className="image-Option">
              Category
            </label>
            <select
              className="image-Option"
              name="category"
              onChange={handleCategoryChange}
              value={formState.category}
              id="category"
            >
              {rows.map((rows) => (
                <option
                  className="image-Option"
                  key={rows.categoryId}
                  value={rows.name}
                >
                  {rows.name}
                </option>
              ))}
              ;
            </select>
          </div>
          {/* Description */}
          <div className="form-group">
            <label htmlFor="description" className="image-Option">
              Description
            </label>
            <textarea
              name="description"
              onChange={handleDescriptionChange}
              value={formState.description}
              id="description"
            />
          </div>
          <Alert show={showErrDesc} className="errorAlert">
            Caractères invalides
            <br />
            ou "Description" vide.
          </Alert>
          <Button
            type="button"
            className="btn-submit"
            onClick={handleSubmit}
            style={{ display: validated }}
          >
            Add product
          </Button>
          &emsp;
          <Button
            className={ButtonCss}
            disabled={cancelButton}
            onClick={() => closeModal()}
          >
            <Spinner animation={animation} role="status" size="sm" />
            {"  "}
            {buttonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
