import React from "react";
import Navbar from "./navBar";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import logo from "../../images/Logo-1.jpg";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import NavBarAdmin from "./navBarAdmin";
import { Row } from "react-bootstrap";

export function WithNav() {
  const [isAdmin, setIsAdmin] = useState(false);
  const user = useAuth0();
  useEffect(() => {
    validateRole();
  }, []);
  async function validateRole() {
    console.log("asdad");
    if (user?.user?.role.length == 1) {
      if (user?.user?.role.includes("Client")) {
        setIsAdmin(false);
      }
    }
    if (user?.user?.role.includes("Admin")) {
      setIsAdmin(true);
    }
    if (user?.user?.role.includes("Manager")) {
      setIsAdmin(true);
    }
  }
  return (
    <>
      {user?.user?.role.includes("Manager") && (
        <div className="navbaradmin">
          <NavBarAdmin />
          <Outlet />
        </div>
      )}

      {!user?.user?.role.includes("Manager") && (
        <div>
          {user?.user?.role.includes("Admin") && (
            <div className="navbaradmin">
              <NavBarAdmin />
              <Outlet />
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default WithNav;
