import React from "react";
import PersonAdd from "../components/PersonAdd";
import FormsContact from "../components/ContactUs/formscontact";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Contact = () => {
  return (
    <Container fluid>
      <Row>
        <FormsContact />
      </Row>
    </Container>
  );
};

export default Contact;
