import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap/";
import Turnstile, { useTurnstile } from "react-turnstile";
import Widget from "./widget";
import { toast } from "react-toastify";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "./forms.css";
// import { render } from '@react-email/render';
// import sendgrid from '@sendgrid/mail';
// import { Email } from './Email';

function FormContact() {
  //set
  const turnstile = useTurnstile();
  const intialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [animation, setAnimation] = useState("");
  const [show, setShow] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [formsValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showSubmit, setSubmitShow] = useState();
  const [message, setMessage] = useState("");
  const [Id] = useState("");

  useEffect(() => {
    //check error --> submit

    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      handleSubmit();
    }
  }, [formErrors]);
  //  sendgrid = require('@sendgrid/mail');
  // sendgrid.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
  // const emailHtml = render(<Email url="https://localhost:3001" />);

  function handleFirstnameChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setFirstname(e.target.value);
  }
  function handleLastnameChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setLastname(e.target.value);
  }
  function handleEmailChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setEmail(e.target.value);
  }
  function handlePhoneChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setPhone(e.target.value);
  }
  function handleMessageChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formsValues, [name]: value });
    setMessage(e.target.value);
  }
  async function handleSubmit() {
    const user = {
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      Message: message,
    };

    // const options = {
    //   from: 'thomro2005@gmail.com',
    //   to: user.Email,
    //   subject: 'hello world',
    //   html: emailHtml,
    // };

    // sendgrid.send(options);

    try {
      setAnimation("border");
      await axios.post(process.env.REACT_APP_AUTH0_API + `api/forms`, user);
      toast.success("Thank you! Your submission has been sent.");
    } catch (err) {
      console.error(err);
      toast.error("Oops.. something went wrong , try one more time.");
    } finally {
      setAnimation("");
    }
  }
  const validationSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formsValues));
    setIsSubmitting(true);
  };

  const validate = (values) => {
    let errors = {};
    const regexnumber = /^[0-9]+$/;
    const regexletter = /^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Please enter an Email adress";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email";
    } else if (values.email.length <= 4) {
      errors.email = "Email has to be more than 4 characters";
    } else if (values.email.length >= 200) {
      errors.email = "Email cannot contain more than 200 characters";
    }
    if (!values.firstName) {
      errors.firstName = "Please enter a first name";
    } else if (values.firstName.length <= 1) {
      errors.firstName = "First name has to be than 1 character";
    } else if (!regexletter.test(values.firstName)) {
      errors.firstName = "First name cannot contain numbers";
    } else if (values.firstName.length >= 200) {
      errors.firstName = "First name cannot contain more than 200 characters";
    }
    if (!values.phone) {
      errors.phone = "Please enter a phone number";
    } else if (values.phone.length < 10) {
      errors.phone = "Phone number has to be more than 9 digits";
    } else if (values.phone.length > 20) {
      errors.phone = "Phone number cannot contain more than 20 digits";
    } else if (!regexnumber.test(values.phone)) {
      errors.phone = "Please include numbers with no spaces only";
    }
    if (!values.lastName) {
      errors.lastName = "Please enter a last name";
    } else if (values.lastName.length <= 1) {
      errors.lastName = "Last name has to be more than 1 character";
    } else if (!regexletter.test(values.lastName)) {
      errors.lastName = "Last name cannot contain numbers";
    } else if (values.lastName.length >= 200) {
      errors.lastName = "Last name cannot contain more than 200 characters";
    }
    return errors;
  };

  return (
    <Stack className="col-md-8 mx-auto">
      <Form noValidate onSubmit={validationSubmit}>
        <Col className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationFormik01">
            <Form.Label>First name *</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              defaultValue={formsValues.firstName}
              onChange={handleFirstnameChange}
            />
            {formErrors.firstName && (
              <span className="error">{formErrors.firstName}</span>
            )}
            {/* <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationFormik02">
            <Form.Label>Last name *</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formsValues.lastName}
              onChange={handleLastnameChange}
              className={formErrors.email && "input-error"}
            />
            {formErrors.lastName && (
              <span className="error">{formErrors.lastName}</span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationFormikUsername">
            <Form.Label>Phone number *</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                aria-describedby="inputGroupPrepend"
                name="phone"
                value={formsValues.phone}
                onChange={handlePhoneChange}
              />
            </InputGroup>
            {formErrors.phone && (
              <span className="error">{formErrors.phone}</span>
            )}
          </Form.Group>
        </Col>
        <Col className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationFormik03">
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={formsValues.email}
              onChange={handleEmailChange}
            />
            {formErrors.email && (
              <span className="error">{formErrors.email}</span>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationFormik04">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="message"
              value={formsValues.message}
              onChange={handleMessageChange}
            />
            {formErrors.message && (
              <span className="error">{formErrors.message}</span>
            )}
          </Form.Group>
          <Turnstile
            sitekey="0x4AAAAAAAIkE1sNjwOUo_td"
            onVerify={(token) => {
              setSubmitShow(token);
            }}
          />
          <Button variant="success" type="submit" disabled={!showSubmit}>
            Send
          </Button>
        </Col>
      </Form>
    </Stack>
  );
}

export default FormContact;
