import { useEffect, useState } from "react";
import { Modal } from "../../components/Admin/Category/Modal.js";
import { ModalEdit } from "../../components/Admin/Category/ModalEdit.js";
import { ModalDelete } from "../../components/Admin/Category/DeleteModal";
import "../../components/Admin/Category/Table.css";
import axios from "axios";
import Loading from "./Loading";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/esm/Button.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { BsArrowRepeat } from "react-icons/bs";
import { useAuth0 } from "@auth0/auth0-react";
export function AdminClientsPage() {
  const [rowsP, setRowsP] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();
  async function GetClients() {
    const product = {
      UserId: user.user.Id,
    };
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    try {
      await axios
        .post(
          process.env.REACT_APP_AUTH0_API + `api/admin/getallcontact`,
          product,
          {
            headers: {
              Authorization: accessToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          const cats = res.data;
          setRowsP(cats);
        });
    } catch (err) {
    } finally {
    }
  }
  useEffect(() => {
    GetClients();
  }, []);

  return (
    <Container fluid>
      <Button
        variant="success"
        onClick={() => {
          GetClients();
        }}
      >
        {" "}
        <BsArrowRepeat /> Refresh{" "}
      </Button>
      <Row>
        <Table striped bordered hover responsive variant="dark">
          <thead>
            <tr>
              <th>Id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {rowsP.map((person) => {
              console.log(rowsP);
              return (
                <tr key={person.clientId}>
                  <td>{person.clientId}</td>
                  <td>{person.firstName}</td>
                  <td>{person.lastName}</td>
                  <td>{person.phone}</td>
                  <td>{person.email}</td>
                  <td>{person.message}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default AdminClientsPage;
