import React from "react";
import Navbar from "./navBar";

import { Outlet } from "react-router";

export function WithNav() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}
export default WithNav;
