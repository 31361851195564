import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import "../Products/ModalP.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProductOption } from "../../../pages/Admin/AdminProductOption";
import Button from "react-bootstrap/esm/Button";
import Alert from "react-bootstrap/Alert";
import { useAuth0 } from "@auth0/auth0-react";

export const ModalDeleteProductOption = ({ closeDeleteProductModal }) => {
  const [imgs, setImgs] = useState([]);
  const [rows, setRows] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/pdf`)
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }, []);

  useEffect(() => {
    GetProducts();
  }, []);

  async function GetProducts() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    await axios
      .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
        headers: {
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        const cats = res.data;
        setRows(cats);
      });
  }

  const [buttonText, setButtonText] = useState("Annuler");
  const [animation, setAnimation] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState("");
  const [deleteCSS, setDeleteCSS] = useState("btn-submit");
  const [alertText, setAlertText] = useState(
    "Êtes-vous certain de votre decision? Vous allez supprimer: "
  );

  async function handleSubmit(e, productOptionToDel) {
    productOptionToDel = ProductOption;

    const toDelete = rows.findIndex(
      (p) => p.productId === productOptionToDel.productId
    );
    setRows(rows.filter((_, idx) => idx !== toDelete));

    const product = {
      Target: productOptionToDel.productOptionId,
      UserId: user.user.Id,
    };
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    await axios
      .post(process.env.REACT_APP_AUTH0_API + `api/productoption`, product, {
        headers: {
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Supprimé: " + productOptionToDel.name);
      })
      .catch((err) => {
        toast.error("error while deleting.");
      });

    try {
      setAlertText("Deleting... : ");
      setDeleteCSS("btn-close-modal");
      setValidated("none");
      setDisabled(true);
      setAnimation("border");
      setButtonText("Deleting...");
      const fileExtension = imgs
        .find(
          (i) => i.ObjectName.split(".")[0] == ProductOption.productOptionId
        )
        .ObjectName.split(".")[1];
      const response = await axios.delete(
        "https://ny.storage.bunnycdn.com/" +
          process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
          "product-Options/" +
          ProductOption.productOptionId +
          "." +
          fileExtension,
        {
          headers: {
            AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
            "content-type": "application/octet-stream",
          },
        }
      );

      toast.success("Image for " + ProductOption.name + " was removed!");
      setAlertText("Succesfully deleted: ");
    } catch (error) {
      toast.error("error in deleting a product option image");
    } finally {
      setDeleteCSS("btn-submit");
      setDisabled(false);
      setAnimation("");
      setButtonText("Ok");
    }
  }

  return (
    <div className="modal-container">
      <div className="modal-deleteP">
        <Alert className="modal-delete-text">
          {alertText} {ProductOption.name}
        </Alert>
        <Button
          type="button"
          className="btn-close-modal"
          onClick={() => handleSubmit()}
          style={{ display: validated }}
        >
          Supprimer
        </Button>
        &emsp;
        <Button
          className={deleteCSS}
          onClick={() => closeDeleteProductModal()}
          disabled={disabled}
        >
          <Spinner animation={animation} role="status" size="sm" />
          {"  "}
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
