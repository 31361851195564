import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import {
  BsFillTrashFill,
  BsFillPencilFill,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";

import { useAuth0 } from "@auth0/auth0-react";

export function OptionAccordion({ rows, imgs, deleteRow, editRow }) {
  return (
    <Container fluid>
      <Row xs={2} sm={3} xxl={6}>
        {rows.map((productoption) => {
          console.log(rows);
          const img = imgs.find(
            (i) => i.ObjectName.split(".")[0] == productoption?.productOptionId
          );
          function imageAvailable() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "product-Options/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "product-Options/undefined"
            ) {
              return;
            } else {
              return (
                process.env.REACT_APP_AUTH0_IMAGES +
                "product-Options/" +
                img?.ObjectName
              );
            }
          }

          function doesExist() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "product-Options/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "product-Options/undefined"
            ) {
              toast.error("No image or PDF was found");
              return;
            } else {
              return (
                process.env.REACT_APP_AUTH0_IMAGES +
                "product-Options/" +
                img?.ObjectName
              );
            }
          }
          function statusText() {
            if (productoption?.status === true) {
              return "Actif";
            } else {
              return "Inactif";
            }
          }
          function statusTextProduct() {
            if (productoption.product?.status === true) {
              return "Actif";
            } else {
              return "Inactif";
            }
          }

          function statusStyle() {
            if (productoption.status === true) {
              return "status-style-actif";
            } else {
              return "status-style-inactif";
            }
          }

          function statusStyleProduct() {
            if (productoption.product?.status === true) {
              return "status-style-actif";
            } else {
              return "status-style-inactif";
            }
          }
          return (
            <>
              <Card border="primary" className="text-center">
                <Card.Header>
                  <Nav variant="pills" defaultActiveKey="#first">
                    <Nav.Item>
                      <Nav.Link href="#first">{productoption?.name}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={doesExist}
                        href={imageAvailable()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="actions">
                          <BsFillFileEarmarkPdfFill />
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body key="#first">
                  <Card.Text className={statusStyle()}>
                    {statusText()}
                  </Card.Text>
                  <Card.Title>{productoption?.name}</Card.Title>
                  <Card.Text className="id-style">
                    {"#" + productoption?.productOptionId}
                  </Card.Text>
                  <Card.Text className="description-style">
                    {productoption?.description}
                  </Card.Text>
                  <Card.Text className={statusStyleProduct()}>
                    {productoption.product?.name} : {statusTextProduct()}
                  </Card.Text>
                  <Card.Footer>
                    <span className="actions">
                      <BsFillTrashFill
                        className="delete-btn"
                        onClick={() => {
                          deleteRow(productoption);
                        }}
                      />
                      <BsFillPencilFill
                        onClick={() => {
                          editRow(productoption);
                        }}
                      />
                    </span>
                  </Card.Footer>
                </Card.Body>
              </Card>
              &emsp;
            </>
          );
        })}
      </Row>
    </Container>
  );
}

export default OptionAccordion;
