import React from "react";
import ControlledCarousel from "../components/controlledCarousel";
import CategoryDisplay from "../components/Admin/Category/CategoryDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
const About = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <ControlledCarousel />
      </Row>
      <div className="homecenter">
        <p>
          ZD Motor Manufacturer (Ningbo Zhongda Leader Transmission Equipment
          CO., Ltd) is professional for producing micro AC and
          <br></br> DC gear motor, small AC gear motor, precision planetary
          gearbox for servo motor, and it has capability to esign and develop
          <br></br> the micro motor, customized motor and precision gearbox,
          motor controller with high quality, and sales team and service of the
          <br></br> company is excellent. By now, the company has 820
          employees,20 engineers and 45 technicians, and got the certification
          of
          <br></br> CUS, CUL, UL, CE, ROHS and ISO9001.
        </p>
      </div>
      <CategoryDisplay />
    </Container>
  );
};

export default About;
