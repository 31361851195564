import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { useEffect, useState } from "react";
import "./ModalP.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Product } from "../../../pages/Admin/AdminProducts";
import { categoryText } from "../../../pages/Admin/AdminProducts";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/esm/Button";
// Import React FilePond
import { FilePond, File, registerPlugin } from "react-filepond";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BsFillArrowDownCircleFill,
  BsCloudUploadFill,
  BsTrashFill,
} from "react-icons/bs";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import e from "cors";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ModalEditProduct = ({
  category,
  closeEditModalProduct,
  onSubmit,
  defaultValue,
}) => {
  const user = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/images`)
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }, []);

  function statusText() {
    if (Product.status === true) {
      return "Actif";
    } else {
      return "Inactif";
    }
  }

  const [formState, setFormState] = useState(
    defaultValue || {
      nom: Product.name,
      status: statusText(),
      category: categoryText,
      description: Product.description,
    }
  );
  const [isUploaded, setIsUploaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [buttonText, setButtonText] = useState("Cancel");
  const [ButtonCss, setButtonCss] = useState("danger");
  const [showErr, setShowErr] = useState(false);
  const [showErrDesc, setShowErrDesc] = useState(false);
  const [validated, setValidated] = useState("");
  const [animation, setAnimation] = useState("");
  const [animationDelete, setAnimationDelete] = useState("");
  const [uploading, setUploading] = useState("Apply new image");
  const [deleting, setDeleting] = useState("Delete image");
  const [disabled, setdisabled] = useState(false);

  const validateForm = () => {
    const regex = /^[[A-Za-zÀ-ÖØ-öø-ÿ0-9-. ,!?:()%&*$#@~/]*$/;
    if (
      !formState.nom ||
      !regex.test(formState.nom) ||
      !formState.description ||
      !regex.test(formState.description)
    ) {
      if (!formState.nom || !regex.test(formState.nom)) {
        setShowErr(true);
      }
      if (!formState.description || !regex.test(formState.description)) {
        setShowErrDesc(true);
      }

      if (formState.description && regex.test(formState.description)) {
        setShowErrDesc(false);
      }
      if (formState.nom && regex.test(formState.nom)) {
        setShowErr(false);
      }
      toast.error("An error occured when editing");
    } else {
      setShowErr(false);
      setShowErrDesc(false);
      setValidated("none");
      return true;
    }
  };

  async function handleSubmit(e) {
    if (isUploaded || files[0]?.file == null) {
      try {
        const product = {
          Nom: formState.nom,
          StatusY: formState.status,
          CategoryName: formState.category,
          Description: formState.description,
          Target: Product.productId,
          UserId: user.user.Id,
        };
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });

        setAnimation("border");
        await axios
          .post(
            process.env.REACT_APP_AUTH0_API + `api/admin/products`,
            product,
            {
              headers: {
                Authorization: accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )

          .then((res) => {
            return res.data.token;
          });

        e.preventDefault();
        if (!validateForm()) return;

        setButtonCss("secondary");
        setButtonText("Ok");
        toast.success("Modifié: " + formState.nom);
        onSubmit(formState);
      } catch (error) {
        toast.error("An error occured while editing product");
      } finally {
        setAnimation("");
      }
    } else if (!isUploaded) {
      toast.error("Please upload or remove new image before applying changes");
    }
  }

  async function handleDeleteImage() {
    try {
      const fileExtension = imgs
        .find((i) => i.ObjectName.split(".")[0] == Product.productId)
        .ObjectName.split(".")[1];
      setDeleting("Deleting image...");
      setAnimationDelete("border");
      const response = await axios.delete(
        "https://ny.storage.bunnycdn.com/" +
          process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
          "products/" +
          Product.productId +
          "." +
          fileExtension,
        {
          headers: {
            AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
            "content-type": "application/octet-stream",
          },
        }
      );

      toast.success("Image for " + Product.name + " was removed!");
    } catch (err) {
      console.error(err);
      toast.error("An error occured while deleting image");
    } finally {
      setAnimationDelete("");
      setDeleting("Delete image");
    }
  }

  async function handleUploadImage() {
    if (files[0]?.file != null) {
      const imagefile = files[0]?.file;
      const imageFileType = "." + imagefile.name.split(".")[1];
      if (
        imageFileType == ".jpg" ||
        imageFileType == ".png" ||
        imageFileType == ".jpeg" ||
        imageFileType == ".raw"
      ) {
        try {
          const fileExtension = imgs
            .find((i) => i.ObjectName.split(".")[0] == Product.productId)
            .ObjectName.split(".")[1];
          setDeleting("Deleting image...");
          setAnimationDelete("border");
          const response = await axios.delete(
            "https://ny.storage.bunnycdn.com/" +
              process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
              "products/" +
              Product.productId +
              "." +
              fileExtension,
            {
              headers: {
                AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
                "content-type": "application/octet-stream",
              },
            }
          );

          toast.success("Image for " + Product.name + " was removed!");
        } catch (err) {
          console.info("No image was deleted");
        } finally {
          setAnimationDelete("");
          setDeleting("Delete image");
        }
        try {
          setAnimation("border");
          setUploading("Applying new image...");
          const response = await axios.put(
            "https://ny.storage.bunnycdn.com/" +
              process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
              "products/" +
              Product.productId +
              imageFileType,
            imagefile,
            {
              headers: {
                AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
                "Content-Type": "application/octet-stream",
              },
            }
          );

          toast.success(imagefile.name + " was Uploaded!");
          setIsUploaded(true);
          setdisabled(true);
        } catch (err) {
          console.error(err);
          toast.error("An error occured while editing image at 2nd step");
        } finally {
          setAnimation("");
          setUploading("Apply new image");
        }
      } else {
        toast.error("Invalid Image Format");
      }
    } else {
      toast.error("Please Input An Image");
    }
  }

  function handleNomChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  function handleDescriptionChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  function handleStatusChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  function handleCategoryChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }
  return (
    <div className="modal-containerP">
      <div className="modalsP">
        <form>
          {/* Image */}
          <div className="form-group">
            <label htmlFor="file">Image</label>

            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={false}
              maxFiles={1}
              name="files" /* sets the file input name, it's filepond by default */
              labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            />
            <p className="image-Information">
              The bigger the image size, the longer it might take to upload and
              delete images.
            </p>
            <Stack gap={1} className="col-md-9 mx-auto">
              <Button
                variant="secondary"
                onClick={handleUploadImage}
                disabled={disabled}
              >
                <Spinner animation={animation} role="status" size="sm" />
                {"  "}
                <BsCloudUploadFill /> {uploading}
              </Button>
              <Button variant="danger" onClick={handleDeleteImage}>
                <Spinner animation={animationDelete} role="status" size="sm" />
                {"  "}
                <BsTrashFill /> {deleting}
              </Button>
            </Stack>
          </div>
          {/* Nom */}
          <div className="form-group">
            <label htmlFor="description">Name</label>
            <textarea
              name="nom"
              onChange={handleNomChange}
              value={formState.nom}
              id="description"
            />
          </div>
          <Alert show={showErr} className="errorAlert">
            Invalid characters
            <br />
            or "Name" empty.
          </Alert>
          {/* status */}
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              name="status"
              onChange={handleStatusChange}
              value={formState.status}
              id="status"
            >
              <option key="1" value="Actif">
                Actif
              </option>
              <option key="2" value="Inactif">
                Inactif
              </option>
            </select>
          </div>
          {/* CategoryId */}
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              name="category"
              onChange={handleCategoryChange}
              value={formState.category}
              id="category"
            >
              {category.map((row) => (
                <option key={row.name} value={row.name}>
                  {row.name}
                </option>
              ))}
              ;
            </select>
          </div>
          {/* Description */}
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              onChange={handleDescriptionChange}
              value={formState.description}
              id="description"
            />
          </div>
          <Alert show={showErrDesc} className="errorAlert">
            Invalid characters
            <br />
            or "Description" empty.
          </Alert>
          <Button
            variant="secondary"
            type="button"
            onClick={handleSubmit}
            style={{ display: validated }}
          >
            <Spinner animation={animation} role="status" size="sm" />
            {"  "}
            <BsFillArrowDownCircleFill /> Apply Changes
          </Button>
          &emsp;
          <Button variant={ButtonCss} onClick={() => closeEditModalProduct()}>
            {buttonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
