import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/navBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Switch } from "react";
import AdminCategory from "./pages/Admin/AdminCategory";
import Homepage from "./pages/Home";
import { Products } from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import { AdminLogin } from "./pages/Admin/AdminLogin";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import Certification from "./pages/Certification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminProductOption from "./pages/Admin/AdminProductOption";
import AdminProducts from "./pages/Admin/AdminProducts";
import LogoutButton from "./pages/Admin/AdminLogOut";
import Home from "./pages/Admin/AdminHome";
import { useAuth0 } from "@auth0/auth0-react";
import WithoutNav from "./components/Navbar/WithoutNav";
import WithNav from "./components/Navbar/WithNav";
import WithNavAdmin from "./components/Navbar/WithNavAdmin";
import "./App.css";
import { AdminClientsPage } from "./pages/Admin/AdminClientsPage";

function App() {
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_TEST);
  return (
    <>
      <ToastContainer theme="colored" />
      {/* <Button onClick={handleButtonClick}>sajdoasdasdasjdoas</Button> */}

      <Router>
        <Routes>
          <Route element={<WithNav />}>
            <Route path="/" element={<Homepage />} />
          </Route>

          <Route element={<WithNav />}>
            <Route path="/products" element={<Products />} />
          </Route>

          <Route element={<WithNav />}>
            <Route path="/aboutus" element={<AboutUs />} />
          </Route>

          <Route element={<WithNav />}>
            <Route path="/contact" element={<ContactUs />} />
          </Route>

          <Route element={<WithNav />}>
            <Route path="/certification" element={<Certification />} />
          </Route>

          <Route element={<WithNavAdmin />}>
            <Route path="/admin" element={<Home />} />
          </Route>
          <Route element={<WithNavAdmin />}>
            <Route path="/admin/category" element={<AdminCategory />} />
          </Route>
          <Route element={<WithNavAdmin />}>
            <Route path="/admin/products" element={<AdminProducts />} />
          </Route>
          <Route element={<WithNavAdmin />}>
            <Route
              path="/admin/productoption"
              element={<AdminProductOption />}
            />
          </Route>
          <Route element={<WithNavAdmin />}>
            <Route path="/admin/clients" element={<AdminClientsPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;
