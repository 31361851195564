import { useEffect, useState } from "react";
import { OptionAccordion } from "../../components/Admin/ProductOption/AdminProducOptionAccordions";
import axios from "axios";
import { ModalProductOption } from "../../components/Admin/ProductOption/ModalProductOption";
import { ModalDeleteProductOption } from "../../components/Admin/ProductOption/ModalDeleteProductOption";
import { ModalEditProductOption } from "../../components/Admin/ProductOption/ModalEditProductOption";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/esm/Button";
import { BsArrowRepeat, BsPlus } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useAuth0 } from "@auth0/auth0-react";

export let defaultProduct;
export let ProductOption;

export function AdminProductOption() {
  const [animation, setAnimation] = useState("border");
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  async function getProductoption() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    try {
      setAnimation("border");
      const product = {
        UserId: user.user.Id,
      };
      const res = await axios.post(
        process.env.REACT_APP_AUTH0_API + `api/productoption`,
        product,
        {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const Prods = res.data;

      setRows(Prods);
      setFilter(Prods);
    } catch {
      toast.error("An error occurred while loading products");
    } finally {
      setAnimation("");
    }
  }

  function getImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/pdf`)
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }

  async function getCategories() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    try {
      await axios
        .post(process.env.REACT_APP_AUTH0_API + `api/products`, product, {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const Prods = res.data;

          setRows2(Prods);
        });
    } catch (err) {
      toast.error("An error occured while getting");
    }
  }
  async function getall() {
    try {
      getCategories();
      getProductoption();
      getImages();
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR");
    }
  }
  useEffect(() => {
    getall();
  }, []);

  function refreshTable() {
    try {
      setdisabled(true);
      getall();
      toast.success("Refreshed!");
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR");
    } finally {
      setTimeout(function () {
        setdisabled(false);
      }, 5000);
    }
  }

  const [disabled, setdisabled] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [filter, setFilter] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditProductOpen, setModalEditProductOpen] = useState(false);
  const [modalProductDeleteOpen, setModalProductDeleteOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);

  const handleDeleteRow2 = (target) => {
    ProductOption = target;

    setModalProductDeleteOpen(true);
  };

  function filter2(e) {
    const catName = e.target.value;

    if (catName === "Inactif") {
      setFilter(rows.filter((p) => p.status === false));
    } else if (catName === "Actif") {
      setFilter(rows.filter((p) => p.status !== false));
    } else if (catName === "All Categories") {
      setFilter(rows);
    } else {
      const indexFinder = rows2.findIndex((c) => c.name === catName);

      setFilter(
        rows.filter((p) => p.categoryId === rows2[indexFinder].categoryId)
      );
    }
  }

  function handleEditsRow2(target) {
    ProductOption = target;

    setRowToEdit(target);
    setModalEditProductOpen(true);
  }

  const handleSubmit = (newRow) => {
    rowToEdit === null
      ? setRows([...rows, newRow])
      : setRows(
          rows.map((currRow, targetIndex) => {
            if (targetIndex !== rowToEdit) {
              return currRow;
            }

            return newRow;
          })
        );
  };

  function setDefaultProduct() {
    if (typeof rows2[0]?.name === "undefined") {
      toast.error("Please add atleast one product");
    } else {
      setModalOpen(true);
      defaultProduct = rows2[0].name;
    }
  }
  return (
    <Container fluid>
      Product Options &emsp;
      <Button
        onClick={() => refreshTable()}
        disabled={disabled}
        variant="outline-success"
      >
        <BsArrowRepeat />
        Refresh
      </Button>
      &emsp;
      <Button
        variant="success"
        onClick={() => {
          setDefaultProduct();
        }}
        className="btn-modals"
      >
        <BsPlus />
        Add
      </Button>
      <Row>
        <div className="spinner-container">
          <Spinner animation={animation} />
        </div>
        <OptionAccordion
          imgs={imgs}
          filter={filter}
          filter2={filter2}
          rows={rows}
          deleteRow={handleDeleteRow2}
          editRow={handleEditsRow2}
        />
        {modalOpen && (
          <ModalProductOption
            closeModal={() => {
              setModalOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}
        {modalEditProductOpen && (
          <ModalEditProductOption
            closeEditModalProduct={() => {
              setModalEditProductOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}
        {modalProductDeleteOpen && (
          <ModalDeleteProductOption
            closeDeleteProductModal={() => {
              setModalProductDeleteOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}
      </Row>
    </Container>
  );
}
export default AdminProductOption;
