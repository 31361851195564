import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import "./Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import { FilePond, File, registerPlugin } from "react-filepond";

export const Modal = ({ closeModal, onSubmit, defaultValue }) => {
  const [formState, setFormState] = useState(
    defaultValue || {
      nom: "",
      status: "Actif",
    }
  );

  const [cancelButton, setCancelButton] = useState(false);
  const [buttonText, setButtonText] = useState("Annuler");
  const [ButtonCss, setButtonCss] = useState("btn-close-modal");
  const [show, setShow] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();
  const [validated, setValidated] = useState("");
  const [animation, setAnimation] = useState("");
  const [files, setFiles] = useState([]);

  function validateForm() {
    const regex = /^[[A-Za-zÀ-ÖØ-öø-ÿ0-9-. ,!?:()%&*$#@~]*$/;

    if (formState.nom && formState.status && regex.test(formState.nom)) {
      setShow(false);
      toast.success("Ajouté: " + formState.nom);
      setValidated("none");
      return true;
    } else {
      setShow(true);
      toast.error("Erreur lors de l'ajout");
    }
  }
  const [status, setStatus] = useState("Actif");
  const [nom, setNom] = useState("");

  const handleSubmit = async (e) => {
    let rowsprod;

    const imagefile = files[0]?.file;
    function verify() {
      if (imagefile != null) {
        const imageFileType = "." + imagefile.type.split("/")[1];
        if (
          imageFileType == ".jpg" ||
          imageFileType == ".png" ||
          imageFileType == ".jpeg" ||
          imageFileType == ".raw"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    if (verify()) {
      try {
        const product = {
          Nom: formState.nom,
          StatusX: status,
          UserId: user.user.Id,
        };

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });

        setAnimation("border");

        if (!validateForm()) return;
        setButtonCss("btn-submit");
        setCancelButton(true);
        setButtonText("Adding product...");
        await axios
          .post(process.env.REACT_APP_AUTH0_API + `api/admin`, product, {
            headers: {
              Authorization: accessToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            return res.data.token;
          });

        e.preventDefault();

        await axios
          .post(
            process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`,
            product,
            {
              headers: {
                Authorization: accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            const Prods = res.data;
            rowsprod = Prods;
          });

        if (files[0]?.file != null) {
          const Product = rowsprod[rowsprod.length - 1];
          const imagefile = files[0]?.file;
          const imageFileType = "." + imagefile.type.split("/")[1];

          const response = await axios.put(
            "https://ny.storage.bunnycdn.com/" +
              process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
              "categories/" +
              Product.categoryId +
              imageFileType,
            imagefile,
            {
              headers: {
                AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
                "Content-Type": "application/octet-stream",
              },
            }
          );

          toast.success(imagefile.name + " was Uploaded!");
        }
        setButtonCss("btn-submit");
        setButtonText("Ok");
        toast.success("Added: " + formState.nom);
        onSubmit(formState);
      } catch (err) {
        toast.error("Error in uploading image, please try again.");
        setButtonText("Ok");
      } finally {
        setCancelButton(false);
        setAnimation("");
      }
    } else {
      toast.error("Inavlid image format");
    }
  };

  function handleNomChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setNom(e.target.value);
  }

  function handleStatusChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setStatus(e.target.value);
  }

  return (
    <div className="modal-container">
      <div className="modals">
        <form>
          {/* Image */}
          <div className="form-group">
            <label htmlFor="file" className="image-Option">
              Image
            </label>

            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={false}
              maxFiles={1}
              name="files" /* sets the file input name, it's filepond by default */
              labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            />
            <p className="image-Information">
              Image will get automatically uploaded once product has been added.
              <br />A category with no image can be added.
            </p>
          </div>
          {/* Nom */}
          <div className="form-group">
            <label htmlFor="description">Nom</label>
            <textarea
              name="nom"
              onChange={handleNomChange}
              value={formState.nom}
              id="description"
            />
          </div>
          <Alert show={show} className="errorAlert">
            Caractères invalides
            <br />
            ou "Nom" vide.
          </Alert>
          {/* status */}
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              name="status"
              onChange={handleStatusChange}
              value={formState.status}
              id="status"
            >
              <option value="Actif">Actif</option>
              <option value="Inactif">Inactif</option>
            </select>
          </div>
          <Button
            type="button"
            className="btn-submit"
            onClick={handleSubmit}
            style={{ display: validated }}
          >
            {"  "}
            Ajouter
          </Button>
          &emsp;
          <Button className={ButtonCss} onClick={() => closeModal()}>
            <Spinner animation={animation} role="status" size="sm" />
            &ensp;{buttonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
