import React from "react";
import Row from "react-bootstrap/Row";
import "./Shop.css";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";

export const AdminProductsCards = ({
  imgs,
  filter,
  filter2,
  rows2,
  deleteRow,
  editRow,
}) => {
  return (
    <div>
      {/*FILTER*/}
      <div className="filter-style">
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            name="category"
            onChange={filter2}
            id="category"
            // className='btn-select'
          >
            <option key="All Categories">All Categories</option>
            <option key="Actif">Actif</option>
            <option key="Inactif">Inactif</option>
            {rows2?.map((row2) => (
              <option key={row2.categoryId}>{row2.name}</option>
            ))}
          </select>
        </div>
      </div>
      {/*PRODUCTS*/}
      <div>
        <Container fluid>
          <Row xs={1} sm={2} md={3} lg={4} xl={5} xxl={6}>
            {filter?.map((product) => {
              const img = imgs.find(
                (i) => i.ObjectName.split(".")[0] == product.productId
              );
              const category = rows2?.find(
                (c) => c.categoryId === product.categoryId
              );

              function statusText() {
                if (product.status === true) {
                  return "Actif";
                } else {
                  return "Inactif";
                }
              }

              function statusTextCat() {
                if (category?.status === true) {
                  return "Actif";
                } else {
                  return "Inactif";
                }
              }

              function statusStyle() {
                if (product.status === true) {
                  return "status-style-actif";
                } else {
                  return "status-style-inactif";
                }
              }

              function statusStyleCategory() {
                if (category?.status === true) {
                  return "status-style-actif";
                } else {
                  return "status-style-inactif";
                }
              }

              function loadingIconStyle() {
                if (
                  process.env.REACT_APP_AUTH0_IMAGES +
                    "products/" +
                    img?.ObjectName ==
                  process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                ) {
                  return "spinner-Style";
                } else {
                  return "";
                }
              }

              function imageAvailable() {
                if (
                  process.env.REACT_APP_AUTH0_IMAGES +
                    "products/" +
                    img?.ObjectName ==
                  process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                ) {
                  return;
                } else {
                  return (
                    process.env.REACT_APP_AUTH0_IMAGES +
                    "products/" +
                    img?.ObjectName
                  );
                }
              }

              function animation() {
                if (
                  process.env.REACT_APP_AUTH0_IMAGES +
                    "products/" +
                    img?.ObjectName ==
                  process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                ) {
                  return "border";
                } else {
                  return "";
                }
              }
              return (
                <Col key={product.productId}>
                  <Card style={{ width: "15rem" }}>
                    <Image
                      id="imageLoad"
                      className="image-style"
                      src={imageAvailable()}
                      thumbnail
                    />
                    <div className={loadingIconStyle()}>
                      <Spinner
                        animation={animation()}
                        role="status"
                        size="sm"
                      />
                    </div>
                    <Card.Header>
                      <Card.Text className={statusStyle()}>
                        {statusText()}
                      </Card.Text>
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>{product.name}</Card.Title>
                      <Card.Text className="id-style">
                        # {product.productId}
                      </Card.Text>
                      <Card.Text className="description-style">
                        {product.description}
                      </Card.Text>
                      <Card.Text className={statusStyleCategory()}>
                        {category?.name}: {statusTextCat()}
                      </Card.Text>
                      <span className="actions">
                        <BsFillTrashFill
                          className="delete-btn"
                          onClick={() => {
                            deleteRow(product);
                          }}
                        />
                        <BsFillPencilFill
                          className="edit-btn"
                          onClick={() => {
                            editRow(product);
                          }}
                        />
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};
