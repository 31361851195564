import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useEffect, useState } from "react";
import {
  BsFillHouseFill,
  BsBoxArrowLeft,
  BsBagFill,
  BsCollectionFill,
  BsFillPeopleFill,
} from "react-icons/bs";
import Offcanvas from "react-bootstrap/Offcanvas";

function NavBarAdmin() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  return (
    <>
      {/* {isAdmin && ( */}
      <Navbar expand={false} className="navbarcolumn2">
        <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xxl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
            placement="start"
          >
            <Offcanvas.Header className="navbarcolumn" closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                Zd motors Management
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="navbarcolumn">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/admin" className="textnavbar">
                  &emsp;
                  <BsFillHouseFill /> Home
                </Nav.Link>
                <Nav.Link href="/admin/clients" className="textnavbar">
                  &emsp;
                  <BsFillPeopleFill /> Clients
                </Nav.Link>
                <Nav.Link href="/admin/category" className="textnavbar">
                  &emsp;
                  <BsCollectionFill /> Category
                </Nav.Link>
                <Dropdown className="textnavbar">
                  <Dropdown.Toggle
                    variant="dark"
                    id="dropdown-basic"
                    className="textnavbar"
                  >
                    <BsBagFill /> Products
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-products">
                    <Dropdown.Item
                      className="textnavbar"
                      href="/admin/products"
                    >
                      Products
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="textnavbar"
                      href="/admin/productoption"
                    >
                      Product Options
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Nav.Link href="/" className="textnavbar">
                  &emsp;
                  <BsBoxArrowLeft /> Leave
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Navbar className="navbarcolumn"></Navbar>
    </>
  );
}

export default NavBarAdmin;
