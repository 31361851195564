import React, { StrictMode } from "react";
import Videoabout from "../components/videoabout";
import imageabout1 from "../images/aboutus-1.jpg";
import imageabout2 from "../images/aboutus-9.jpg";
import imageabout3 from "../images/aboutus-11.jpg";
import imageabout4 from "../images/aboutus-4.jpg";
import imageabout5 from "../images/aboutus-5.jpg";
import imageabout6 from "../images/aboutus-6.jpg";
import imageabout7 from "../images/aboutus-16.jpg";
import imageabout8 from "../images/aboutus-8.jpg";
import imageabout9 from "../images/aboutus-12.jpg";
import imageabout10 from "../images/aboutus-29.jpg";
import imageabout11 from "../images/aboutus-26.jpg";
import imageabout12 from "../images/aboutus-35.jpg";
import imageabout13 from "../images/aboutus-31.jpg";
import imageabout14 from "../images/aboutus-33.jpg";
import imageabout15 from "../images/TestingEquipment3.jpg";
import Stack from "react-bootstrap/Stack";

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const Contact = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Videoabout />
      </Row>
      <Row className="justify-content-md-center">
        <p className="text-font1">
          ZD Motor Manufacturer (Ningbo Zhongda Leader Transmission Equipment
          CO., Ltd) is professional for producing micro AC and DC gear motor,
          BLDC motor, BLDC planetary gear motor, small AC gear motor, precision
          planetary gearbox for servo motor, and it has capability to design and
          develop the micro motor, customized motor and precision gearbox, motor
          controller with high quality, and sales team and service of the
          company is excellent. By now, the company has 820 employees,20
          engineers and 45 technicians, and got the certification of CUS, CUL,
          UL, CE, ROHS and ISO9001. Production capacity ( per Month ) : Micro
          AC/DC Gear Motor 100 000 units BLDC Gear Motor 20 000 units Small AC
          Gear Motor 10 000 units Planetary Gearbox 10 000 units Machine
          equipement, Assembly line and Warehouse guarante ZD's product quality
        </p>
      </Row>
      <h1 className="text-font2"> Machine-outil CNC</h1>
      <Row
        xs={2}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        className="justify-content-md-center"
      >
        <Image src={imageabout1} alt="" className="hover-zoom"></Image>
        <Image src={imageabout2} alt="" className="hover-zoom"></Image>
        <Image src={imageabout3} alt="" className="hover-zoom"></Image>
        <Image src={imageabout4} alt="" className="hover-zoom"></Image>
        <Image src={imageabout5} alt="" className="hover-zoom"></Image>
        <Image src={imageabout6} alt="" className="hover-zoom"></Image>
        <Image src={imageabout7} alt="" className="hover-zoom"></Image>
        <Image src={imageabout8} alt="" className="hover-zoom"></Image>
        <Image src={imageabout9} alt="" className="hover-zoom"></Image>
      </Row>
      <h1 className="text-font2"> Production line</h1>
      <Row
        xs={2}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        className="justify-content-md-center"
      >
        <Image src={imageabout10} alt="" className="hover-zoom" />
        <Image src={imageabout11} alt="" className="hover-zoom" />
        <Image src={imageabout12} alt="" className="hover-zoom" />
      </Row>
      <h1 className="text-font2"> Warehouse and Testing Equipment</h1>
      <Row
        xs={2}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        className="justify-content-md-center"
      >
        <Image src={imageabout13} alt="" className="hover-zoom" />
        <Image src={imageabout14} alt="" className="hover-zoom" />
        <Image src={imageabout15} alt="" className="hover-zoom" />
      </Row>
    </Container>
  );
};

export default Contact;
