import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Category } from "../../../pages/Admin/AdminCategory";
import "./Modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilePond, File, registerPlugin } from "react-filepond";
import Button from "react-bootstrap/esm/Button";
import {
  BsFillArrowDownCircleFill,
  BsCloudUploadFill,
  BsTrashFill,
} from "react-icons/bs";
import Stack from "react-bootstrap/Stack";

export const ModalEdit = ({ closeEditModal, onSubmit, defaultValue }) => {
  const [rows, setRows] = useState([]);
  const [imgs, setImgs] = useState([]);

  async function getCategories() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    try {
      await axios
        .post(
          process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`,
          product,
          {
            headers: {
              Authorization: accessToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          const cats = res.data;
          setRows(cats);
        });
    } catch (err) {
      toast.error("A problem occured while getting the categories.");
    }
  }
  function getCategoryImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/images/category`, {})

      .then((res) => {
        const cats = res.data;
        setImgs(cats);
      });
  }
  useEffect(() => {
    getCategoryImages();
    getCategories();
  }, []);

  function statusTexted() {
    if (Category.status === true) {
      return "Actif";
    } else {
      return "Inactif";
    }
  }
  const [formState, setFormState] = useState(
    defaultValue || {
      nom: Category.name,
      status: statusTexted(),
    }
  );
  const [ButtonCss, setButtonCss] = useState("btn-close-modal");
  const [buttonText, setButtonText] = useState("Annuler");
  const [show, setShow] = useState(false);

  const [animationDelete, setAnimationDelete] = useState("");
  const [uploading, setUploading] = useState("Apply new image");
  const [deleting, setDeleting] = useState("Delete image");
  const [disabled, setdisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [validated, setValidated] = useState("");
  const [animation, setAnimation] = useState("");
  const [status, setStatus] = useState("Actif");
  const [nom, setNom] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  const validateForm = () => {
    const regex = /^[[A-Za-zÀ-ÖØ-öø-ÿ0-9-. ,!?:()%&*$#@~]*$/;

    if (formState.nom && regex.test(formState.nom)) {
      setShow(false);
      setValidated("none");
      return true;
    } else {
      setShow(true);
      toast.error("Erreur lors de l'édition");
    }
  };

  async function handleEditRow(e, idx) {
    const toEdit = Category;
    if (files[0]?.file == null || isUploaded) {
      try {
        const product = {
          Target: toEdit.categoryId,
          StatusX: formState.status,
          Nom: formState.nom,
          UserId: user.user.Id,
        };
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
            scope: "read:current_user openlid profile name",
          },
        });

        setAnimation("border");
        axios.post(process.env.REACT_APP_AUTH0_API + `api/admin`, product, {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        e.preventDefault();
        if (!validateForm()) return;
        setButtonCss("btn-submit");
        setButtonText("Ok");
        onSubmit(formState);
        toast.success("Modifié: " + toEdit.name);
      } catch (error) {
        toast.error("An error occured while editing category");
      } finally {
        setAnimation("");
      }
    } else {
      toast.error("Please upload or remove new image before applying changes");
    }
  }
  async function handleDeleteImage() {
    try {
      const fileExtension = imgs
        .find((i) => i.ObjectName.split(".")[0] == Category.categoryId)
        ?.ObjectName.split(".")[1];
      setDeleting("Deleting image...");
      setAnimationDelete("border");
      const response = await axios.delete(
        "https://ny.storage.bunnycdn.com/" +
          process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
          "categories/" +
          Category.categoryId +
          "." +
          fileExtension,
        {
          headers: {
            AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
            "content-type": "application/octet-stream",
          },
        }
      );

      toast.success("Image for " + Category.name + " was removed!");
    } catch (err) {
      console.error(err);
      toast.error("An error occured while deleting image");
    } finally {
      setAnimationDelete("");
      setDeleting("Delete image");
    }
  }

  async function handleUploadImage() {
    if (files[0]?.file != null) {
      const imagefile = files[0]?.file;
      const imageFileType = "." + imagefile.name.split(".")[1];

      try {
        const fileExtension = imgs
          .find((i) => i.ObjectName.split(".")[0] == Category.categoryId)
          ?.ObjectName.split(".")[1];
        setDeleting("Deleting image...");
        setAnimationDelete("border");
        const response = await axios.delete(
          "https://ny.storage.bunnycdn.com/" +
            process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
            "categories/" +
            Category.categoryId +
            "." +
            fileExtension,
          {
            headers: {
              AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
              "content-type": "application/octet-stream",
            },
          }
        );
        toast.success("Image for " + Category.name + " was removed!");
      } catch (err) {
      } finally {
        setAnimationDelete("");
        setDeleting("Delete image");
      }

      try {
        setAnimation("border");
        setUploading("Applying new image...");
        const response = await axios.put(
          "https://ny.storage.bunnycdn.com/" +
            process.env.REACT_APP_BUNNYNET_IMAGES_DIRECTORY +
            "categories/" +
            Category.categoryId +
            imageFileType,
          imagefile,
          {
            headers: {
              AccessKey: process.env.REACT_APP_AUTH0_IMAGES_KEY,
              "Content-Type": "application/octet-stream",
            },
          }
        );

        setIsUploaded(true);
        toast.success(imagefile.name + " was Uploaded!");
        setdisabled(true);
      } catch (err) {
        toast.error("An error occured while uploading new image");
      } finally {
        setAnimation("");
        setUploading("Apply new image");
      }
    } else {
      toast.error("Please Input An Image");
    }
  }
  function handleNomChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setNom(e.target.value);
  }

  function handleStatusChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setStatus(e.target.value);
  }

  return (
    <div className="modal-container">
      <div className="modals">
        <form>
          {/* Image */}
          <div className="form-group">
            <label htmlFor="file" className="image-Option">
              Image
            </label>

            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={false}
              maxFiles={1}
              name="files" /* sets the file input name, it's filepond by default */
              labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            />
            <p className="image-Information">
              The bigger the image size, the longer it might take to upload and
              delete images.
            </p>
            <Stack gap={1} className="col-md-9 mx-auto">
              <Button
                variant="secondary"
                onClick={handleUploadImage}
                disabled={disabled}
              >
                <Spinner animation={animation} role="status" size="sm" />
                {"  "}
                <BsCloudUploadFill /> {uploading}
              </Button>
              <Button variant="danger" onClick={handleDeleteImage}>
                <Spinner animation={animationDelete} role="status" size="sm" />
                {"  "}
                <BsTrashFill /> {deleting}
              </Button>
            </Stack>
          </div>
          {/* Nom */}
          <div className="form-group">
            <label htmlFor="description">Nom</label>
            <textarea
              name="nom"
              onChange={handleNomChange}
              value={formState.nom}
              id="description"
            />
          </div>
          <Alert show={show} className="errorAlert">
            Caractères invalides
            <br />
            ou "Nom" vide.
          </Alert>
          {/* status */}
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              name="status"
              onChange={handleStatusChange}
              value={formState.status}
              id="status"
            >
              <option value="Actif">Actif</option>
              <option value="Inactif">Inactif</option>
            </select>
          </div>
          <Button
            type="button"
            className="btn-submit"
            onClick={handleEditRow}
            style={{ display: validated }}
          >
            <Spinner animation={animation} role="status" size="sm" />
            {"  "}
            Sauvegarder
          </Button>
          &emsp;
          <Button className={ButtonCss} onClick={() => closeEditModal()}>
            {buttonText}
          </Button>
        </form>
      </div>
    </div>
  );
};
