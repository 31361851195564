import { useEffect, useState } from "react";
import { AdminProductsCards } from "../../components/Products/AdminProductsCards";
import axios from "axios";
import { ModalProduct } from "../../components/Admin/Products/ModalProduct";
import { ModalDeleteProduct } from "../../components/Admin/Products/ModalDeleteProduct";
import { ModalEditProduct } from "../../components/Admin/Products/ModalEditProduct";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { string } from "yup";
import { BsArrowRepeat, BsPlus } from "react-icons/bs";

import { useAuth0 } from "@auth0/auth0-react";
export let categoryText;
export let defaultCategory;
export let Product;

export function AdminProducts() {
  const [animation, setAnimation] = useState("border");
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  async function getProducts() {
    const product = {
      UserId: user.user.Id,
    };
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    try {
      setAnimation("border");

      const res = await axios.post(
        process.env.REACT_APP_AUTH0_API + `api/products`,
        product,
        {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const Prods = res.data;

      setRows(Prods);
      setFilter(Prods);
    } catch {
      toast.error("An error occurred while loading products");
    } finally {
      setAnimation("");
    }
  }

  function getImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/images`)
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }

  async function getCategories() {
    const product = {
      UserId: user.user.Id,
    };

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });
    try {
      const cats = await axios.post(
        process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`,
        product,
        {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setRows2(cats.data);
    } catch {
      toast.error("Error while loading categories");
    }
  }

  async function getall() {
    try {
      getCategories();
      getProducts();
      getImages();
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR, reload page");
    }
  }
  useEffect(() => {
    getall();
  }, []);

  function refreshTable() {
    try {
      setdisabled(true);
      getall();
      toast.success("Refreshed!");
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR");
    } finally {
      setTimeout(function () {
        setdisabled(false);
      }, 5000);
    }
  }

  const [disabled, setdisabled] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [filter, setFilter] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditProductOpen, setModalEditProductOpen] = useState(false);
  const [modalProductDeleteOpen, setModalProductDeleteOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);

  const handleDeleteRow2 = (target) => {
    Product = target;

    setModalProductDeleteOpen(true);
  };

  function filter2(e) {
    const catName = e.target.value;

    if (catName === "Inactif") {
      setFilter(rows.filter((p) => p.status === false));
    } else if (catName === "Actif") {
      setFilter(rows.filter((p) => p.status !== false));
    } else if (catName === "All Categories") {
      setFilter(rows);
    } else {
      const indexFinder = rows2.findIndex((c) => c.name === catName);

      setFilter(
        rows.filter((p) => p.categoryId === rows2[indexFinder].categoryId)
      );
    }
  }

  function handleEditRow2(target) {
    Product = target;

    const index = rows2.find((c) => c.categoryId === Product.categoryId);
    const categoryd = index?.name;
    categoryText = categoryd;

    setRowToEdit(target);
    setModalEditProductOpen(true);
  }

  const handleSubmit = (newRow) => {
    rowToEdit === null
      ? setRows([...rows, newRow])
      : setRows(
          rows.map((currRow, targetIndex) => {
            if (targetIndex !== rowToEdit) {
              return currRow;
            }

            return newRow;
          })
        );
  };
  function setDefaultCategory() {
    if (typeof rows2[0]?.name === "undefined") {
      toast.error("Please add atleast one category");
    } else {
      setModalOpen(true);
      defaultCategory = rows2[0]?.name;
    }
  }
  return (
    <Container fluid>
      <Alert variant="warning" className="alert-display">
        Loading means that an image is loading or doesn't exist
      </Alert>
      &emsp;
      <Button
        onClick={() => refreshTable()}
        disabled={disabled}
        variant="outline-success"
      >
        <BsArrowRepeat /> Refresh
      </Button>
      &emsp;
      <Button
        variant="success"
        onClick={() => {
          setDefaultCategory();
        }}
      >
        <BsPlus />
        Add
      </Button>
      <Row>
        <div className="spinner-container">
          <Spinner animation={animation} />
        </div>
        <AdminProductsCards
          imgs={imgs}
          filter={filter}
          filter2={filter2}
          rows={rows}
          rows2={rows2}
          deleteRow={handleDeleteRow2}
          editRow={handleEditRow2}
        />
        {modalOpen && (
          <ModalProduct
            closeModal={() => {
              setModalOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}

        {modalEditProductOpen && (
          <ModalEditProduct
            category={rows2}
            closeEditModalProduct={() => {
              setModalEditProductOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}
        {modalProductDeleteOpen && (
          <ModalDeleteProduct
            closeDeleteProductModal={() => {
              setModalProductDeleteOpen(false);
              setRowToEdit(null);
            }}
            onSubmit={handleSubmit}
          />
        )}
      </Row>
    </Container>
  );
}
export default AdminProducts;
