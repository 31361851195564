import Carousel from "react-bootstrap/Carousel";
import homepage1 from "../images/homepage1.jpg";
import homepage2 from "../images/homepage2.jpg";
import homepage3 from "../images/homepage3.jpg";
import Image from "react-bootstrap/Image";
import "./ComponentCSS/Carousel.css";
import "bootstrap/dist/css/bootstrap.css";

function controlledCarousel() {
  return (
    <Carousel className="carousel-style">
      <Carousel.Item interval={4000}>
        <Image
          className="image-adjust"
          text="First slide"
          src={homepage1}
          rounded
        />
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <Image
          className="image-adjust"
          text="Second slide"
          src={homepage2}
          rounded
        />
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <Image
          className="image-adjust"
          text="Third slide"
          src={homepage3}
          rounded
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default controlledCarousel;
