import React from "react";
import "../../Products/Shop.css";
import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useAuth0 } from "@auth0/auth0-react";

function CategoryDisplayProducts() {
  const [filter, setFilter] = useState([]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [pdfLink, setPDFLink] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [pdfs, setPDFs] = useState([]);

  const [pdfOption, setPDFOption] = useState("");

  const [animation, setAnimation] = useState("border");
  const { getAccessTokenSilently } = useAuth0();
  const user = useAuth0();
  const [imgsProds, setImgsProds] = useState([]);
  const [products, setProducts] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);

  const [imgs, setImgs] = useState([]);
  const [category, setCategory] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  function handleShow(e) {
    setCategory(e);
    setOpen(true);
  }
  function handleCloseDetails() {
    setDisabled(true);
    setPDFOption("");
    setOpenDetails(false);
  }
  function handleShowDetails(e) {
    setProducts(e);
    setOpenDetails(true);
  }
  function getImages() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + "api/images/category")
      .then((res) => {
        setImgs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }

  function getCategories() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + `api/category`, {})
      .then((res) => {
        const Prods = res.data;

        setRows2(Prods.filter((c) => c.status != false));
      });
  }
  async function getProducts() {
    try {
      setAnimation("border");
      await axios
        .get(process.env.REACT_APP_AUTH0_API + `api/client/products`, {})
        .then((res) => {
          const Prods = res.data;

          setRows(Prods);
          setFilter(
            Prods.filter((c) => c?.status != false).filter(
              (p) => p.category?.status != false
            )
          );
        });
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while loading products");
    } finally {
      setAnimation("");
    }
  }
  function getImagesProds() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + "api/images")
      .then((res) => {
        setImgsProds(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }
  function getPDFs() {
    axios
      .get(process.env.REACT_APP_AUTH0_API + "api/pdf")
      .then((res) => {
        setPDFs(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }
  async function getProductOptions() {
    await axios
      .get(process.env.REACT_APP_AUTH0_API + "api/client/productOption")
      .then((res) => {
        console.log(res.data);
        setProductOptions(res.data);
      })
      .catch((err) => {
        toast.error("An error occured while loading images");
      });
  }
  useEffect(() => {
    getProductOptions();
    getPDFs();
    getImages();
    getCategories();
    getProducts();
    getImagesProds();
  }, []);

  // FUNCTIONS FOR MODALS

  function animationLoader(product) {
    const img = imgsProds.find(
      (i) => i.ObjectName.split(".")[0] == product?.productId
    );
    if (
      process.env.REACT_APP_AUTH0_IMAGES + "products/" + img?.ObjectName ==
      process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
    ) {
      return "border";
    } else {
      return "";
    }
  }
  function loadingIconStyle(product) {
    const img = imgsProds.find(
      (i) => i.ObjectName.split(".")[0] == product?.productId
    );

    if (
      process.env.REACT_APP_AUTH0_IMAGES + "products/" + img?.ObjectName ==
      process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
    ) {
      return "spinner-Style-Details";
    } else {
      return "";
    }
  }

  function imageAvailable(product) {
    const prod = product;
    const img = imgsProds.find(
      (i) => i.ObjectName.split(".")[0] == prod?.productId
    );
    if (
      process.env.REACT_APP_AUTH0_IMAGES + "products/" + img?.ObjectName ==
      process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
    ) {
      return;
    } else {
      return process.env.REACT_APP_AUTH0_IMAGES + "products/" + img?.ObjectName;
    }
  }
  function pdfChange() {
    console.log("A");
  }

  function pdfAvailable(product) {
    const prod = product;
    const prodopt = productOptions.find(
      (o) => o.product.productId == prod.productId
    );
    const img = pdfs.find(
      (i) => i.ObjectName.split(".")[0] == prodopt?.productOptionId
    );
  }
  function pdfChange(e) {
    const pdfId = e.target.value;
    setPDFOption(productOptions.find((o) => o.productOptionId == pdfId)?.name);
    const pdf = pdfs.find((i) => i.ObjectName.split(".")[0] == pdfId);
    if (
      process.env.REACT_APP_AUTH0_IMAGES +
        "product-Options/" +
        pdf?.ObjectName ==
      process.env.REACT_APP_AUTH0_IMAGES + "product-Options/undefined"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
      setPDFLink(
        process.env.REACT_APP_AUTH0_IMAGES +
          "product-Options/" +
          pdf?.ObjectName
      );
    }
  }
  function doesExist() {}
  // HTML

  return (
    /*Categories*/
    <Container fluid>
      <Row xs={2} md={4}>
        {rows2.map((categories) => {
          const img = imgs.find(
            (i) => i.ObjectName.split(".")[0] == categories.categoryId
          );
          function loadingIconStyle() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "spinner-Style";
            } else {
              return "";
            }
          }

          function imageAvailable() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return;
            } else {
              return (
                process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName
              );
            }
          }

          function animation() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "border";
            } else {
              return "";
            }
          }

          return (
            <Col key={categories.categoryId}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                variant="primary"
                onClick={() => {
                  handleShow(categories);
                }}
              >
                <Card
                  style={{
                    border: "none",
                    maxWidth: "22rem",
                  }}
                >
                  <Card.Title
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {categories.name}
                  </Card.Title>
                  <Image
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      maxWidth: "14rem",
                    }}
                    id="imageLoad"
                    src={imageAvailable()}
                    rounded
                  />
                  <div className={loadingIconStyle()}>
                    <Spinner animation={animation()} role="status" size="sm" />
                  </div>
                </Card>
              </Button>
            </Col>
          );
        })}
      </Row>
      {/* Products Modal */}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{category.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row xs={1} sm={2} md={2} lg={4} className="justify-content-center">
            {rows
              .filter((p) => p.categoryId == category.categoryId)
              .filter((p) => p.status == true)
              .map((product) => {
                const categoryName = rows2.find(
                  (c) => c.categoryId == product.categoryId
                );
                const img = imgsProds.find(
                  (i) => i.ObjectName.split(".")[0] == product?.productId
                );

                function loadingIconStyle() {
                  if (
                    process.env.REACT_APP_AUTH0_IMAGES +
                      "products/" +
                      img?.ObjectName ==
                    process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                  ) {
                    return "spinner-Style";
                  } else {
                    return "";
                  }
                }

                function imageAvailable() {
                  if (
                    process.env.REACT_APP_AUTH0_IMAGES +
                      "products/" +
                      img?.ObjectName ==
                    process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                  ) {
                    return;
                  } else {
                    return (
                      process.env.REACT_APP_AUTH0_IMAGES +
                      "products/" +
                      img?.ObjectName
                    );
                  }
                }

                function animation() {
                  if (
                    process.env.REACT_APP_AUTH0_IMAGES +
                      "products/" +
                      img?.ObjectName ==
                    process.env.REACT_APP_AUTH0_IMAGES + "products/undefined"
                  ) {
                    return "border";
                  } else {
                    return "";
                  }
                }
                return (
                  // Products
                  <Col style={{ maxWidth: "18rem" }} key={product?.productId}>
                    <Card
                      style={{
                        minHeight: "18rem",
                      }}
                    >
                      <Image
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        id="imageLoad"
                        src={imageAvailable()}
                        rounded
                      />
                      <div className={loadingIconStyle()}>
                        <Spinner
                          animation={animation()}
                          role="status"
                          size="sm"
                        />
                      </div>
                      <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        <Card.Text className="category-style">
                          {categoryName?.name}
                        </Card.Text>
                        <span
                          style={{
                            marginLeft: "24%",
                          }}
                        >
                          <Button
                            type="button"
                            style={{
                              fontWeight: "600",
                            }}
                            variant="outline-primary"
                            onClick={() => {
                              handleShowDetails(product);
                            }}
                          >
                            View Details
                          </Button>
                        </span>
                      </Card.Body>
                    </Card>
                    &emsp;
                  </Col>
                );
              })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* PRODUCT DETAILS */}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openDetails}
        onHide={handleCloseDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>{products.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row xs={1} sm={2}>
              <Col>
                <Image
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    minWidth: "18rem",
                  }}
                  id="imageLoad"
                  src={imageAvailable(products)}
                  rounded
                />
                <div className={loadingIconStyle(products)}>
                  <Spinner
                    animation={animationLoader(products)}
                    role="status"
                    size="sm"
                  />
                </div>
                <p>{products.name}</p>
                <p>{products.description}</p>{" "}
              </Col>
              <Col>
                <Form.Select
                  onChange={pdfChange}
                  aria-label="Default select example"
                >
                  <option>Choose a power</option>;
                  {productOptions
                    .filter((o) => o.product?.productId == products?.productId)
                    .filter((o) => o.status != false)
                    .map((rows) => (
                      <option
                        className="image-Option"
                        key={rows.productOptionId}
                        value={rows.productOptionId}
                      >
                        {rows?.name}
                      </option>
                    ))}
                </Form.Select>
                {pdfOption}
                <Stack direction="vertical" className="col-1 mx-auto" gap={2}>
                  <Button
                    disabled={disabled}
                    style={{
                      minWidth: "4rem",
                    }}
                    variant="danger"
                    onClick={doesExist}
                    href={pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="actions">
                      <BsFillFileEarmarkPdfFill />
                    </span>
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
export default CategoryDisplayProducts;
